import React , {useState} from 'react'
import { useEffect } from 'react';




const Footer = () => {
  let rol = localStorage.getItem('roles');
const [redirectPath, setRedirectPath] = useState("https://kyndryl.mybluemix.net/")
const [className, setClassName] = useState("hidden")
 
  const validaRol=(rol)=>{
    if (rol!=='BASIC'){
     setRedirectPath("https://project-status.mybluemix.net/")
     setClassName("disable:hidden") 
    }
 }
    
    
   useEffect(() => {
    validaRol(rol)
   }, [rol])
   
    
  return (
     
      <div className="fixed grid inset-x-0 bottom-0 mt-4 place-content-center justify-center text-center w-full  border-t-2 bg-cloud text-warm-red">
            <a  href={redirectPath}target="_blank" rel="noreferrer noopener"> 
            <img src='../../Assets/logotipo.svg' className='mx-auto h-20 w-30' alt="Avatar-logo"/>
            <p className={className} >Estado de proyectos </p>
            </a>
      </div>
  )
}

export default Footer