import axiosInstance from '../axios/axios'

const get = () => {
    return axiosInstance.api.get('/apps/') 
}

const create = (app) => {
    return axiosInstance.api.post('/apps/', app) 
}

const remove = (id) => {
    return axiosInstance.api.delete(`/apps/${id}`) 
}

const update = (id, app) => {
    return axiosInstance.api.put(`/apps/${id}`, app) 
}

// eslint-disable-next-line
export default {
    get,
    create,
    remove,
    update,
}