import React from 'react';

import {
    Route, Routes,
} from 'react-router-dom';

import Layout from './pages/Layout/Layout';
import AppRoutes from './routes/routes';
import Login from './pages/Login/Login';



function App() {
    return (
        
        <Routes>
            <Route exact path='/' element={<Login />} />
            <Route path='/*' element={
                <>
                    <Layout>
                        <AppRoutes />
                    </Layout>
                </>
                    
                
               } />
                
        </Routes>
        
    );
}

export default App;
