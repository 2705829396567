import ErrorAlert from '../../../common/ErrorAlert/ErrorAlert';

import './CustomerForm.css'

import { Button, Icon, Box, TextField, Autocomplete, Checkbox, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const CustomerForm = ({ addInstance, options, customerHandler, closeModal, create, error, customer }) => {

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
        <>
            <div className='customer-form'>

                <div className='customer-form__header'>
                    <Icon className='customer-form__icon'>group</Icon>
                    <h3>{customer._id ? 'Editar Cliente' : 'Nuevo Cliente'}</h3>
                </div>
                <Box
                    className='customer-form__body'
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField className='customer-form__input' id="name" name="name" value={customer.name} label="Nombre Campo" variant="outlined" required onChange={customerHandler} />
                    <TextField className='customer-form__input' id="urlLogo" name="urlLogo" value={customer.urlLogo} label="Logo" variant="outlined" required onChange={customerHandler} />
                    <Autocomplete
                        className='customer-form__input'
                        multiple
                        limitTags={3}
                        id="combo-box-demo"
                        options={options}
                        onChange={addInstance}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Aplicaciones" placeholder="Aplicaciones" />
                        )}
                        renderTags={(value, getTagProps) => {
                            const numTags = value.length;
                            return (
                                <Typography variant="body2">
                                    {value
                                        .slice(0, 1)
                                        .map((option, _) => option.name)
                                        .join(", ")}

                                    {numTags > 1 && ` +${numTags - 1} más`}
                                </Typography>
                            );
                        }}
                    />
                </Box>
                <div className='customer-form__footer'>
                    <Button onClick={closeModal}>Cerrar</Button>
                    <Button onClick={create}>Guardar</Button>
                </div>
                <ErrorAlert error={error} />
            </div>
        </>
    )
}

export default CustomerForm;