import ErrorAlert from '../../../common/ErrorAlert/ErrorAlert';
import './AppForm.css'

import { Button, Icon, Box, TextField } from '@mui/material';


const AppForm = ({ appHandler, closeModal, create, error, app }) => {

    return (
        <>
            <div className='app-form'>

                <div className='app-form__header'>
                    <Icon className='app-form__icon'>group</Icon>
                    <h3>{app._id ? 'Editar App' : 'Nueva App'}</h3>
                </div>
                <Box
                    className='app-form__body'
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField className='app-form__input' id="name" name="name" value={app.name} label="Nombre" variant="outlined" required onChange={appHandler} />
                    <TextField className='app-form__input' id="urlLogo" name="urlLogo" value={app.urlLogo} label="Logo" variant="outlined" required onChange={appHandler} />
                    <TextField className='app-form__input' id="urlApp" name="urlApp" value={app.urlApp} label="URL" variant="outlined" required onChange={appHandler} />
                    <TextField className='app-form__input' id="description" name="description" value={app.description} label="Descripcion" variant="outlined" required onChange={appHandler} />
                </Box>
                <div className='app-form__footer'>
                    <Button onClick={closeModal}>Cerrar</Button>
                    <Button onClick={create}>Guardar</Button>
                </div>
                <ErrorAlert error={error} />
            </div>
        </>
    )
}

export default AppForm;