import React from 'react';

export default function ErrorPage(props){
    return (
        <div style={{
            display: 'flex',
            height: '80vh',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap'
        }}>
            <div style={{display: 'flex', flexDirection: 'column' , alignItems: 'center', padding: '2.5rem'}}>
                <h1 style={{ fontSize: '86px', fontWeight: '700', padding: '0', lineHeight: '.5'}}>500</h1>
                <h2 style={{ fontSize: '54px', fontWeight: '500'}}>error</h2>
                <h5>Wooops, las cosas están algo inestables por aquí.</h5>
                <h5>Le recomendamos volver más tarde</h5>
            </div>
            <div style={{padding: '2.5rem'}}>
                {props.children}
            </div>

        </div>
    )
}
