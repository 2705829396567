import React, { useEffect, useState, useCallback } from 'react';
import getApps from '../../api/AdminResources/customers'
import Card from '../../components/Home/Card';
import ErrorPage from '../../components/UI/ErrorPage/ErrorPage';
import Loader from '../../components/UI/Loader/Loader';
//import { AppsContext } from '../../hooks/context/AppsContext';



const MyApps = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState()
  const [apps, setApps] = useState();


  const fetchApps = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await getApps.getById(localStorage.getItem('appId'));
      setApps(
        data
      );

    } catch (err) {
      setIsLoading(false)
      setError('Error interno del servidor')
    }
  }, []);

  useEffect(() => {
    fetchApps()
  }, [fetchApps])


  useEffect(() => {
    if (apps) {
      setIsLoading(false)
    }
  }, [apps, setIsLoading]);

  if (isLoading) return <Loader />
  if (error) return <ErrorPage />

  return (
    <div className='flex flex-col items-center justify-center'>
      <div className="flex m-2 ">
        <h1 className="text-center text-2xl m-8 text-spruce">Catálogo de Servicios</h1>
        <div className='relative h-[100px] w-[200px] '>
          <img className=" object-cover bg-white h-full w-full rounded-[20px] border-2 border-warm-red/60 shadow-sm shadow-warm-red/50"
            alt="Customer-logo" src={apps?.urlLogo} />
        </div>
      </div>

      <div className='relative w-[900px] h-[150px] rounded-lg bg-cloud/60  shadow-xl inset-0  justify-center text-center mt-8'>
          <p className='m-8 text-spruce' >
            La Plataforma de Automatización Avatar está compuesta por profesionales calificados en diversas disciplinas como Desarrollo, Automatización, Innovación y más, quienes colaboran con nuestros Partners y les ayudan a alcanzar sus objetivos porque estamos comprometidos con su éxito.

          </p>

      </div>
      <div className='flex'>
        <div className="grid gap-10 lg:grid-cols-2 xl:grid-cols-3 xl:gap-16 m-10 p-10 place-items-center">
          {apps?.apps.map((i, e) => (
            <Card key={e} id={i?._id} name={i?.name} linkApp={i?.urlApp} logo={i?.urlLogo} target={"_blank"} />
          ))}
        </div>
      </div>


    </div>

  )
}
export default MyApps