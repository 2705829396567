import React, { useState, useCallback, useEffect } from 'react';


import axiosInstances from '../../api/axios/axios';

import Navbar from '../../components/Layout/Navbar/Navbar';
import Footer from '../../components/Layout/Footer/Footer';
import customerApi from '../../api/AdminResources/customers'
import userApi from '../../api/AdminResources/users'
import { CustomerContext } from '../../hooks/context/CustomerProvider';

import {
    Navigate,
    useNavigate
} from 'react-router-dom';


const isLoggedIn = () => {
    let token = localStorage.getItem('accessToken');

    if (token) {
        axiosInstances.api.defaults.headers.common.Authorization = `Bearer ${token}`;
        return true
    }

    return false
}
const initialUser = {
    username: '',
    password: '',
    customers: [],
    roles: ['BASIC'],
    mail: '',
    isActive: true,
}

const initialCustomer = {
    _id: '',
    name: '',
    urlLogo: '',
    apps: [],
}
const isValidUser = (user) => {
    const mailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (!user) return false
    if (!user.username) return false
    if (!user.mail || !mailRegex.test(user.mail)) return false
    return true
}
const isValidCustomer = (customer) => {
    if (!customer) return false
    if (!customer.name) return false
    if (!customer.urlLogo) return false
    if (!customer.apps) return false
    return true
}

export default function Layout(props) {
    const [show, setShow] = useState(false);
    let navigate = useNavigate();


    const menuHandler = () => {
        setShow((prev) => !prev);
    }

    const logoutHandler = () => {
        localStorage.clear();
        console.log('logout')
        navigate('/', { replace: true });
    }
    

    const [customersFromContext, setCustomersFromContext] = useState([])
    const [users, setUsers] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState();
    const [info, setInfo] = useState()

    const fetchCustomers = useCallback(async () => {
        try {

            const response = await customerApi.get()
            const customer = response.data
            setCustomersFromContext(customer)

        } catch (err) {
            setError('Error al obtener datos del servidor')
            setIsLoading(false)
        }
    }, [])

    useEffect(() => {
        fetchCustomers()
    }, [fetchCustomers])

    useEffect(() => {
        if (customersFromContext && customersFromContext.length >= 0) {
            setIsLoading(false)
        }
    }, [customersFromContext])

    const deleteUser = async (permissionId) => {
        if (window.confirm('¿Está seguro de que desea eliminar este usuario?')) {
            try {
                await userApi.remove(permissionId)
                setInfo('Usuario eliminado satisfactoriamente')
                setUsers(users.filter((permission) => permission._id !== permissionId))
            } catch (error) {
                setError('Error al eliminar usuario')
                setIsLoading(false)
            }
        }
    }
    return (
        <>
            {isLoggedIn() ?
            <>
                <CustomerContext.Provider value={{
                     customersFromContext, 
                     initialUser, 
                     isValidUser,
                     deleteUser,
                     error,
                     setError,
                     info,
                     setInfo,
                     users, 
                     setUsers
                }}>
                <div className='relative h-screen font-Everett-Regular'>
                        <div className=''>
                            <Navbar
                                show={show}
                                menuHandler={menuHandler}
                                logout={logoutHandler}
                            />
                           
                        </div>

                       
                        {props.children}

                       
                        <Footer/>
                        
                    </div>
                </CustomerContext.Provider>
                   
             </>   :
                <Navigate to="/" replace={true}/>
            }
        </>
    );
};
