import React, {useContext, useCallback, useEffect, useState } from 'react';


import customerApi from '../../api/AdminResources/customers';
import appApi from '../../api/AdminResources/apps';

import ErrorAlert from '../../components/common/ErrorAlert/ErrorAlert';
import InfoAlert from '../../components/common/InfoAlert/InfoAlert';
import Loader from '../../components/UI/Loader/Loader';

import CommonTable from '../../components/common/Table/Table'
import NewcustomerModal from '../../components/AdminResources/Customers/CustomerModal'



const initialCustomer = {
    _id: '',
    name: '',
    urlLogo: '',
    apps: [],
}

const isValidCustomer = (customer) => {
    if (!customer) return false
    if (!customer.name) return false
    if (!customer.urlLogo) return false
    if (!customer.apps) return false
    return true
}

const CustomerManagement = () => {

    const [customers, setCustomers] = useState()
    const [customer, setCustomer] = useState(initialCustomer)

    const [apps, setApps] = useState();
    const [selectedApps, setSelectedApps] = useState([]);

    const [showCustomerModal, setShowCustomerModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [formError, setFormError] = useState()

   
    

    const fetchCustomers = useCallback(async () => {
        try {
            const response = await customerApi.get()
            const customer = response.data
            setCustomers(customer)

        } catch (err) {
            setError('Error al obtener datos del servidor')
            setIsLoading(false)
        }
    }, [])

    const fetchApps = useCallback(async () => {
        try {
            const { data } = await appApi.get()
            setApps(data)
        } catch (err) {
            setError('Error al obtener los datos de apps')
            setIsLoading(false)
        }
    }, []);

    const createCustomer = async () => {
        if (isValidCustomer(customer)) {
            try {
                setIsLoading(true)
                if (customer._id) {
                    await customerApi.update(customer._id, { ...customer, apps: selectedApps })
                    setInfo('Cliente actualizado satisfactoriamente')
                } else {
                    delete customer._id;
                    await customerApi.create({ ...customer, apps: selectedApps  })
                    setInfo('Cliente creado satisfactoriamente')
                }

                newCustomerModalHandler()
                fetchCustomers()

            } catch (err) {
                setError('Error al crear cliente')
                setIsLoading(false)
            }
        } else {
            setFormError('Se requieren llenar todos los campos / ingrese un mail válido')
        }
    }

    const deleteCustomer = async (permissionId) => {
        if (window.confirm('¿Está seguro de que desea eliminar este cliente?')) {
            try {
                await customerApi.remove(permissionId)
                setInfo('cliente eliminado satisfactoriamente')
                setCustomers(customers.filter((permission) => permission._id !== permissionId))
            } catch (error) {
                setError('Error al eliminar cliente')
                setIsLoading(false)
            }
        }
    }
    

    const customerHandler = (event) => {
        setCustomer({
            ...customer,
            [event.target.name]: event.target.value,
        })
        
    }

    const newCustomerModalHandler = (customerID) => {
        if (typeof customerID === 'string') {
            setCustomer(customers.find((customer) => customer._id === customerID))
        } else {
            setCustomer(initialCustomer)
        }
        setShowCustomerModal(prev => !prev)
    }

    const addAppsHandler = (event, selected) => {
        setSelectedApps(selected.map((el) => el._id));
    }

    useEffect(() => {
        fetchCustomers()
        fetchApps()
    }, [fetchCustomers,fetchApps])

    useEffect(() => {
        if (
            (customers && customers.length >= 0) &&
            (apps && apps.length >= 0)
        ) {
            setIsLoading(false)
        }
    }, [customers, apps])

    useEffect(() => {
        if (formError) {
            if (isValidCustomer(customer)) {
                setFormError()
            }
        }

    }, [formError, customer])

    useEffect(() => {
        if (info) {
            setTimeout(() => {
                setInfo()
            }, 5000)
        }
    }, [info])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError()
            }, 5000)
        }
    }, [error])



    return (
        <>
            {isLoading
                ? <Loader />
                : <>
                    <div className='flex flex-col '>
                        <div className='rounded-md shadow-md'>
                    <CommonTable
                                    data={customers}
                                    onBtnClick={newCustomerModalHandler}
                                    deleteRow={deleteCustomer}
                                    title={"Clientes"}
                                    dataClass={'Customer'}
                                />
                                <NewcustomerModal
                                    options={apps}
                                    show={showCustomerModal}
                                    instanceHandler={addAppsHandler}
                                    modalHandler={newCustomerModalHandler}
                                    customerHandler={customerHandler}
                                    create={createCustomer}
                                    formError={formError}
                                    customer={customer}
                                />
                                <ErrorAlert error={error} />
                                <InfoAlert info={info} />
                    </div>
                            
                            
                    </div>
                        
                </>
            }
        </>
    )
}

export default CustomerManagement