/* eslint-disable import/no-anonymous-default-export */
import axiosInstance from './axios/axios';

const login = ({ username, password }) => {
    return axiosInstance.auth.post('/login', {
        username,
        password,
    });
}

export default {
    login,
}
