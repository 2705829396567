import React from "react";
import Alert from '@mui/material/Alert';

const InfoAlert = ({ info }) => {
    if (!info) return null
    
    return (
        <div style={{position: 'absolute', top: '75px', right: '1rem'}}>
            <Alert severity="info">{info}</Alert>
        </div>
        

    )
}

export default InfoAlert