import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import authApi from '../../api/auth';
import Loader from '../../components/UI/Loader/Loader';

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isLoading, setIsLoading] = useState();
    const [redirectPath, setRedirectPath] = useState('/home');


    const authentificate = async () => {
        try {
            setIsLoading(true);
            // TODO: use backend
            const {data}=  await authApi.login({ username, password });
           
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('roles', data.roles);
            localStorage.setItem('userId',data.id);

            if (data.roles === 'ADMIN') setRedirectPath('/admin_resources');

            if (data) {
                setIsLoggedIn(true);
            } else {
                throw new Error('Invalid Credentials');
            }
        } catch (e) {
            console.log(e);
            setUsername('');
            setPassword('');
            setError('Invalid Credentials');
            setTimeout(() => {
                setError(null);
            }, 5000);
            setIsLoading(false);
        }
    };

    const onUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    };

    useEffect(() => {
        if (isLoggedIn) {
            setIsLoading(false);
        }
    }, [isLoggedIn]);

   
    return (
        <>
        <div className='absolute mx-auto w-screen h-screen'>
            <img className="object-cover h-full w-full fixed"
            alt="Customer-logo" src="../../Assets/ss.jpg" />
            </div>
        <div className='relative min-h-screen flex flex-col justify-center items-center' >
            {isLoggedIn ? (
                <Navigate to={redirectPath} />
            ) : (
                <div className=" border border-warm-red  rounded-lg place-items-center text-center mx-auto shadow-warm-red  shadow-xl bg-cloud  p-5">
                    <img
                        className="mx-auto h-15 w-25"
                        src="../../Assets/Kyndryl_logo.svg"
                        alt="Kyndryl-Logo"
                    />
                     {/* <h3>Bienvenidos</h3> */}

                    {isLoading
                        ? <Loader />
                        : (
                            <>
                                <form className='mt-10 mx-4'>
                                    <input
                                        className='block shadow-sm focus:ring-1 focus:ring-warm-red focus:outline-none  border-warm-red/40 border rounded my-4 mx-auto py-1 text-left '
                                        placeholder="Usuario"
                                        name="username"
                                        type="text"
                                        autoComplete='username'
                                        value={username}
                                        onChange={onUsernameChange}
                                       
                                    />
                                    <input
                                        className={`block shadow-sm focus:ring-1 focus:ring-warm-red focus:outline-none  border-warm-red/40 border rounded my-4 mx-auto py-1 text-left ${error !== '  ' ? '' : "ring-warm-red "
                                            }`}
                                        style={
                                            error && password === ""
                                                ? { border: "" }
                                                : {}
                                        }
                                        autoComplete="current-password"
                                        placeholder="Contraseña"
                                        type="password"
                                        value={password}
                                        onChange={onPasswordChange}
                                    ></input>
                                    <p hidden={!error} className="text-spruce ">
                                        {error}
                                    </p>

                                </form>
                                <button className="mx-auto mt-4 rounded shadow-lg shadow-stone bg-warm-red p-2 text-white transition hover:scale-110 " 
                                        onClick={authentificate}>
                                <p>Ingresar</p>
                                </button>
                            </>
                        )}
                     <img
                        className="mx-auto h-14 w-25"
                        src="../../Assets/logotipoV3.png"
                        alt="Kyndryl-Logo"
                    />
                    <p className='text-sm font-bold text-gray-800'>By Kyndryl</p>
                </div>

            )}
        </div>
        </>
        
    );
}