import React from "react";

import { getUserViews } from "../../../utils/role_handler";
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuAlt1Icon } from '@heroicons/react/outline' 
import { NavLink } from "react-router-dom";





const Navbar = (props) => {
	const views = getUserViews()
	  function classNames(...classes) {
		  return classes.filter(Boolean).join(' ')
		}
	return (
		<Disclosure as="nav" className="bg-cloud shadow-lg rounded-md w-screen">
		  {({ open }) => (
			<>
			  <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
				<div className="relative flex items-center justify-between h-16">
				  
				  <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
								<div className="flex justify-start lg:w-0 lg:flex-1">
									<a href="/home">
										<span className="sr-only">Kindryl</span>
										<img
											className="h-8 w-auto sm:h-10"src="http://imgfz.com/i/FB2W9hz.png"
											alt="Kyndryl-Logo"
											
										/>
									</a>
								</div>
				  </div>
				  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
					{/* Profile dropdown */}
					<Menu as="div" className="ml-3 relative">
					  <div>
						<Menu.Button className="bg-warm-red p-1 rounded-full text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-warm-red focus:ring-white">
						  <span className="sr-only">Open user menu</span>
						  <MenuAlt1Icon
							className="h-6 w-6 rounded-full"
							src=""
							alt=""
						  />
						</Menu.Button>
					  </div>
					  <Transition
						as="div"
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					  >
						<Menu.Items className=" origin-top-right right-0 mt-10 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-spruce ring-opacity-5 focus:outline-none">
						{views.map((view) => (
							 <Menu.Item  key={view.label}>
							 {({ active }) => (
								   <NavLink
								 to={view.path}
								 onClick={view.path === "/logout" ? props.logout : null}
							   >
							   <span
							   key={view.label}  className={classNames(
								active
								  ? "bg-warm-red text-white transition scale-105 items-end "
								  : "text-black hover:bg-warm-red hover:text-white",
								"block px-3 py-2 rounded-md text-base font-medium"
							  )}
							  aria-current={active ? "page" : undefined}
							   >
								 {view.label}
							   </span>
							   </NavLink>
							 )}
						   </Menu.Item>
						  ))}
						</Menu.Items>
					  </Transition>
					</Menu>
				  </div>
				</div>
			  </div>
	
			  <Disclosure.Panel className="sm:hidden">
				<div className="px-2 pt-2 pb-3 space-y-1">
				  {views.map((item) => (
					<Disclosure.Button
					  key={item.label}
					  as="a"
					  href={item.path}
					  className={classNames(
						item.current
						  ? "bg-spruce text-white"
						  : "text-spruce hover:bg-spruce hover:text-white",
						"block px-3 py-2 rounded-md text-base font-medium"
					  )}
					  aria-current={item.current ? "page" : undefined}
					>
					  {item.label}
					</Disclosure.Button>
				  ))}
				</div>
			  </Disclosure.Panel>
			</>
		  )}
		</Disclosure>
	  );
};

export default Navbar;
