import AppForm from './Form/AppForm'

import { Modal } from '@mui/material';

const NewAppForm = ({ options, instanceHandler, show, modalHandler, appHandler, create, formError, app }) => {
    return (
        <Modal
            open={show}
            onClose={modalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div>
                <AppForm
                    appHandler={appHandler}
                    closeModal={modalHandler}
                    create={create}
                    error={formError}
                    app={app}
                />
            </div>

        </Modal>
    )
}

export default NewAppForm;