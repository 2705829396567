import ErrorAlert from '../../../common/ErrorAlert/ErrorAlert';

import './UpdatePasswordForm.css'

import { Button, Icon, Box, TextField } from '@mui/material';

const UpdatePasswordForm = ({ closeModal, updatePassword, changedPassword, error, passwordHandler }) => {

    return (
        <>
            <div className='user-form'>

                <div className='user-form__header'>
                    <Icon className='user-form__icon'>password</Icon>
                    <h3>{'Editar Password'}</h3>
                </div>
                <Box
                    className='user-form__body'
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField className='user-form__input' id="password" name="password" value={changedPassword.password} label="Contraseña" variant="outlined" required onChange={passwordHandler} />
                </Box>
                <div className='user-form__footer'>
                    <Button onClick={closeModal}>Cerrar</Button>
                    <Button onClick={updatePassword}>Guardar</Button>
                </div>
                <ErrorAlert error={error} />
            </div>
        </>
    )
}

export default UpdatePasswordForm;