import React from "react";
import Alert from '@mui/material/Alert';

const ErrorAlert = ({ error }) => {
    if (!error) return null
    
    return (
        <div style={{position: 'absolute', top: '75px', right: '1rem'}}>
            <Alert severity="error">{error}</Alert>
        </div>
    )
}

export default ErrorAlert