import React, { useContext } from 'react'
import ErrorAlert from '../../../common/ErrorAlert/ErrorAlert';

import './UserForm.css'
import { Button, Icon, Box, TextField, Autocomplete, Checkbox, Typography, Select, MenuItem, FormControl, FormControlLabel, Switch, InputLabel } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';



const UserForm = ({ addCustomerHandler, options, userHandler, closeModal, createUser, editUser, error, user }) => {

    const [checked, setChecked] = React.useState(false);
    const [allCustomers, setAllCustomers] = React.useState(options);

    const handleChange = (event) => {
        setChecked(event.target.checked)
        addCustomerHandler(event, allCustomers)
        userHandler(event)
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
        <>
            <div className='user-form'>

                <div className='user-form__header'>
                    <Icon className='user-form__icon'>group</Icon>
                    <h3>{user._id ? 'Editar Usuario' : 'Nuevo Usuario'}</h3>
                </div>
                <Box
                    className='user-form__body'
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField className='user-form__input' id="username" name="username" value={user.username} label="Nombre usuario" variant="outlined" required onChange={userHandler} />
                    {user._id ? '' :
                        <TextField className='user-form__input' id="password" name="password" value={user.password} label="Contraseña" variant="outlined" required onChange={userHandler} />
                    }
                    <TextField className='user-form__input' id="mail" name="mail" value={user.mail} label="Correo" variant="outlined" required onChange={userHandler} />
                    <FormControlLabel className='user-form__input' control={
                        <Switch
                            id='customers'
                            name="customers"
                            value={allCustomers}
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} label="Asignar todos los clientes" />
                    {checked === true ? '' : <Autocomplete
                        className='user-form__input'
                        multiple
                        limitTags={3}
                        id="combo-box-demo"
                        options={options}
                        onChange={addCustomerHandler}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField {...params} label="Cliente" placeholder="Cliente" />
                        )}
                        renderTags={(value, getTagProps) => {
                            const numTags = value.length;
                            return (
                                <Typography variant="body2">
                                    {value
                                        .slice(0, 1)
                                        .map((option, _) => option.name)
                                        .join(", ")}

                                    {numTags > 1 && ` +${numTags - 1} más`}
                                </Typography>
                            );
                        }}
                    />}

                    <FormControl className='user-form__input'>
                        <InputLabel id="demo-simple-select-autowidth-label">Rol</InputLabel>
                        <Select
                            className='user-form__input'
                            labelId="active-select"
                            id="roles"
                            value={user.roles}
                            name='roles'
                            onChange={userHandler}
                            variant='outlined'
                            label="Rol"
                        >
                            <MenuItem value={'BASIC'}>BASIC</MenuItem>
                            <MenuItem value={'ADMIN'}>ADMIN</MenuItem>
                            <MenuItem value={'DPE'}>DPE</MenuItem>
                        </Select>
                    </FormControl>

                </Box>
                <div className='user-form__footer'>
                    <Button onClick={closeModal}>Cerrar</Button>
                    {user._id ? <Button onClick={editUser}>Guardar</Button> :
                        <Button onClick={createUser}>Guardar</Button>
                    }

                </div>
                <ErrorAlert error={error} />
            </div>
        </>
    )
}

export default UserForm;