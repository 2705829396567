import React, { useCallback, useEffect, useState } from 'react';

import appApi from '../../api/AdminResources/apps';

import ErrorAlert from '../../components/common/ErrorAlert/ErrorAlert';
import InfoAlert from '../../components/common/InfoAlert/InfoAlert';
import Loader from '../../components/UI/Loader/Loader';

import CommonTable from '../../components/common/Table/Table'
import NewAppModal from '../../components/AdminResources/Apps/AppModal'


const initialApp = {
    _id: '',
    name: '',
    urlLogo: '',
    urlApp: '',
    description: '',
}

const isValidApp = (app) => {
    if (!app) return false
    if (!app.name) return false
    if (!app.urlLogo) return false
    if (!app.urlApp) return false
    if (!app.description) return false
    return true
}

const AppManagement = () => {

    const [apps, setApps] = useState()
    const [app, setApp] = useState(initialApp)

    const [showAppModal, setShowAppModal] = useState(false)

    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState()
    const [info, setInfo] = useState()
    const [formError, setFormError] = useState()

    const fetchApps = useCallback(async () => {
        try {
            const response = await appApi.get()
            const app = response.data
            setApps(app)
        } catch (err) {
            setError('Error al obtener datos del servidor')
            setIsLoading(false)
        }
    }, [])

    const createApp = async () => {
        if (isValidApp(app)) {
            try {
                setIsLoading(true)
                if (app._id) {
                    await appApi.update(app._id, { ...app })
                    setInfo('App actualizada satisfactoriamente')
                } else {
                    delete app._id;
                    await appApi.create({ ...app })
                    setInfo('App creada satisfactoriamente')
                }

                newAppModalHandler()
                fetchApps()

            } catch (err) {
                setError('Error al crear app')
                setIsLoading(false)
            }
        } else {
            setFormError('Se requieren llenar todos los campos / ingrese un mail válido')
        }
    }

    const deleteApp = async (permissionId) => {
        if (window.confirm('¿Está seguro de que desea eliminar esta app?')) {
            try {
                await appApi.remove(permissionId)
                setInfo('app eliminada satisfactoriamente')
                setApps(apps.filter((permission) => permission._id !== permissionId))
            } catch (error) {
                setError('Error al eliminar app')
                setIsLoading(false)
            }
        }
    }

    const appHandler = (event) => {
        setApp({
            ...app,
            [event.target.name]: event.target.value
        })
    }

    const newAppModalHandler = (appID) => {
        console.log(appID)
        if (typeof appID === 'string') {
            setApp(apps.find((app) => app._id === appID))
        } else {
            setApp(initialApp)
        }
        
        setShowAppModal(prev => !prev)
    }

    useEffect(() => {
        fetchApps()
    }, [fetchApps])

    useEffect(() => {
        if (
            (apps && apps.length >= 0)
        ) {
            setIsLoading(false)
        }
    }, [apps])

    useEffect(() => {
        if (formError) {
            if (isValidApp(app)) {
                setFormError()
            }
        }

    }, [formError, app])

    useEffect(() => {
        if (info) {
            setTimeout(() => {
                setInfo()
            }, 5000)
        }
    }, [info])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError()
            }, 5000)
        }
    }, [error])

    return (
        <>
            {isLoading
                ? <Loader />
                : <>
                     <div className='flex flex-col '>
                        <div className='rounded-md shadow-md'>
                            <CommonTable
                                data={apps}
                                onBtnClick={newAppModalHandler}
                                deleteRow={deleteApp}
                                title={"Apps"}
                                dataClass={'App'}
                            />
                            <NewAppModal
                                show={showAppModal}
                                modalHandler={newAppModalHandler}
                                appHandler={appHandler}
                                create={createApp}
                                formError={formError}
                                app={app}
                            />
                            <ErrorAlert error={error} />
                            <InfoAlert info={info} />
                        </div>


                    </div>
                </>
            }
        </>
    )
}

export default AppManagement