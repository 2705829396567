import UpdatePasswordForm from './Form/UpdatePasswordForm'

import { Modal } from '@mui/material';

const NewUpdatePasswordModal = ({ show, modalHandler, updatePassword, passwordHandler, formError, changedPassword }) => {
    return (
        <Modal
            open={show}
            onClose={modalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div>
                <UpdatePasswordForm
                    closeModal={modalHandler}
                    updatePassword={updatePassword}
                    changedPassword={changedPassword}
                    error={formError}
                    passwordHandler={passwordHandler}
                />
            </div>
        </Modal>
    )
}

export default NewUpdatePasswordModal;