import React, { useCallback, useEffect, useState, } from 'react';
import { Navigate } from 'react-router-dom';
import userApi from '../../api/AdminResources/users';
import Card from '../../components/Home/Card';
import ErrorPage from '../../components/UI/ErrorPage/ErrorPage';
import Loader from '../../components/UI/Loader/Loader';
//import { AppsContext } from '../../hooks/context/AppsContext';




const Home=()=> {

  const [customers, setCustomers] = useState();
  const [aux, setAux] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState()
  const token= localStorage.getItem('accessToken');
  const id = localStorage.getItem('userId');
 

  const fetchCustomers = useCallback(async () => {
    ;
    try {
      setIsLoading(true)
      const {data} = await userApi.getById(token, id);
      setCustomers(data.customers?.sort((a, b) => b.apps?.length - a.apps?.length));
      setAux(data.customers.length)
      
    } catch (err) {
      setIsLoading(false)
      setError('Error interno del servidor')
    }
  }, [token, id]);

  useEffect(() => {
    fetchCustomers()
  }, [fetchCustomers])

  useEffect(() => {
    if (customers) {
      setIsLoading(false)
    }
  }, [customers]);

 

  if (isLoading) return <Loader />
  if (error) return <ErrorPage />

 

  const valida=(customers,aux)=>{
      if (aux > 1) { 
        return (
          <>
            <div className='block max-h-screen w-screen items-center justify-center'>
            <img
                        className="mx-auto h-32 w-52"
                        src="../../Assets/Kyndryl_logo.svg"
                        alt="Kyndryl-Logo"
                    />
              <div className='grid lg:grid-cols-2 xl:grid-cols-3 m-10 p-10 place-items-center'>
                {customers.map((e, i) =>
                  <Card key={i} id={e?._id} logo={e.urlLogo} name={e.name} linkApp={e.url}/>
                )}
              </div>
            </div>
          </>
        )
      }
      else {
        return ( localStorage.setItem('appId',customers[0]._id),<Navigate to={"/MyApps"} />)
      }
    }

  
 return valida(customers,aux,)
  
};

export default Home;