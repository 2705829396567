import DisplayHeader from '../DisplayHeader/DisplayHeader';

import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { Button, Icon } from '@mui/material';

const CommonTable = ({ data, onBtnClick, passwordEdit, deleteRow, title, dataClass }) => {

    let rows = []

    switch (dataClass) {
        case 'App':
            rows = data.map((el, index) => ({
                _id: el._id,
                name: el.name,
                urlApp: el.urlApp,
                urlLogo: el.urlLogo,
                description: el.description,
                id: index
            }));
            break;
        case 'Customer':
            rows = data.map((el, index) => ({
                _id: el._id,
                name: el.name,
                urlLogo: el.urlLogo,
                apps: el.apps.map(el => el.name),
                id: index
            }));
            break;
        case 'User':
            rows = data.map((el, index) => ({
                _id: el._id,
                username: el.username,
                mail: el.mail,
                customers: el.customers.map(el => el.name),
                roles: el.roles,
                id: index
            }));
            break;

        default:
            break;
    }

    const QuickSearchToolbar = () => {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pb: 0,
                }}
            >
                <GridToolbarQuickFilter
                    sx={{
                        width: '100%'
                    }}
                />
            </Box>
        );
    }

    const rowKeys = Object.keys(rows[0]).filter(
        (el) => !["_id", "id"].includes(el)
    );

    const columns = rowKeys.map((el) => ({
        field: el,
        headerName: el?.toUpperCase(),
        width: 250,
    }));

    columns.push({
        field: 'actions',
        headerName: 'Acciones',
        width: 200,
        renderCell: (params) => {
            return (
                <>
                    <span><Icon onClick={() => deleteRow(params.row._id)}>delete</Icon></span>
                    <span><Icon onClick={() => onBtnClick(params.row._id)}>editt</Icon></span>
                    <span><Icon onClick={() => passwordEdit(params.row.username)}>password</Icon></span>
                </>
            )
        }
    })

    return (
        <div className='m-4 mt-6'>
            <DisplayHeader
                title={` ${title} del sistema`}
                description={''}
            >
                <Button variant="container" style={{ color: 'red', borderColor: 'red' }} onClick={onBtnClick}>
                    Nuevo<Icon>add</Icon>
                </Button>
            </DisplayHeader>
            <div style={{ height: 400, width: '100%', }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    components={{ Toolbar: QuickSearchToolbar }}
                />
            </div>
        </div>
    )
}

export default CommonTable;