import UserForm from './Form/UserForm'

import { Modal } from '@mui/material';

const UserModal = ({ options, addCustomerHandler, show, modalHandler, userHandler, createUser, editUser,formError, user }) => {
    return (
        <Modal
            open={show}
            onClose={modalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div>
                <UserForm
                    addCustomerHandler={addCustomerHandler}
                    options={options}
                    userHandler={userHandler}
                    closeModal={modalHandler}
                    createUser={createUser}
                    editUser={editUser}
                    error={formError}
                    user={user}
                />
            </div>

        </Modal>
    )
}

export default UserModal;