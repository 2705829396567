import CustomerForm from './Form/CustomerForm'

import { Modal } from '@mui/material';

const NewCustomerModal = ({ options, instanceHandler, show, modalHandler, customerHandler, create, formError, customer }) => {
    return (
        <Modal
            open={show}
            onClose={modalHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div>
                <CustomerForm
                    options={options}
                    addInstance={instanceHandler}
                    customerHandler={customerHandler}
                    create={create}
                    closeModal={modalHandler}
                    error={formError}
                    customer={customer}
                />
            </div>

        </Modal>
    )
}

export default NewCustomerModal;