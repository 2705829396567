const getUserRoles = function(){
    return localStorage.getItem('roles')
}

const isAdmin = function(roles) {
    return roles.includes('ADMIN')
}

const getUserViews = function() {
    const roles = getUserRoles() || [];

    if (isAdmin(roles)) {
        return [
            {
                path: '/admin_resources',
                icon: 'group',
                label: 'Gestión del sistema',
            },
            {
                path: '/home',
                icon: 'home',
                label: 'Inicio'
            },
            {
                path: '/logout',
                icon: 'exit_to_app',
                label: 'Logout',
            },
        ]
    } else {
        return [
            {
                path: '/home',
                icon: 'home',
                label: 'Inicio'
            },
            {
                path: '/logout',
                icon: 'exit_to_app',
                label: 'Logout',
            }
        ]
    }
}

module.exports = {
    getUserViews
}