import axiosInstance from '../axios/axios'

const get = () => {
    return axiosInstance.api.get('/customers/') 
}

const create = (customer) => {
    return axiosInstance.api.post('/customers/', customer) 
}

const remove = (id) => {
    return axiosInstance.api.delete(`/customers/${id}`
    
    ) 
}
const getById = ( id , token) => {
    return axiosInstance.api.get(`/customers/${id}` ) 
}

const update = (id, customer) => {
    return axiosInstance.api.put(`/customers/${id}`, customer) 
}

// eslint-disable-next-line
export default {
    get,
    create,
    remove,
    update,
    getById
}