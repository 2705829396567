import React from 'react'


const Card = ( {id, logo='', name, linkApp="/MyApps", target, }) => {

  return (
    <>
      <div className=" relative transition duration-200 hover:-translate-y-2 hover:-translate-x-1 hover:shadow-warm-red  hover:border-warm-red border-2 border-cloud  shadow-lg mb-20  rounded-[20px]">
       
          <button className=" relative justify-center text-center h-[230px] w-[300px] overflow-hidden rounded-[20px] text-deep-forest  hover:text-warm-red" onClick={() =>  localStorage.setItem('appId',id)}>
            <a href={linkApp} target={target} rel="noopener noreferrer" >
              <img
                className="absolute inset-0 object-fit h-[200px] w-[300px]"
                alt="Customer-logo"
                src={logo}
              />
              <p className='w-full absolute my-3 inset-x-0 bottom-0 text-lg font-bold text-gray-800'>{name}</p>
            </a>
          </button>
      </div>
    </>
  );
}

export default Card