import React from 'react'

import { Routes, Route } from 'react-router-dom';

import Home from '../pages/Home/Home';
import MyApps from '../pages/Home/MyApps';
import AdminResources from '../pages/AdminResources/AdminResources';
import { CustomerProvider } from '../hooks/context/CustomerProvider';




const AppRoutes = () => {
    return (
        <Routes>
            
            <Route exact path='/home' element={
                <>
                     <Home />
                </>
               } 
            />
            <Route exact path='/MyApps' element={
                <>
                    <MyApps />
                </>
            } /> 
            <Route exact path='/admin_resources' element={
                   
                         <AdminResources />
                  
                    
                
           
            } />
            <Route element={<h1>404 Not Found</h1>} />
        </Routes>
    )
}

export default AppRoutes