import axiosInstance from '../axios/axios'

const get = () => {
    return axiosInstance.api.get('/users/') 
}

const getById = (token, id) => {
    return axiosInstance.api.get(`/users/${id}`)
}

const create = (user) => {
    return axiosInstance.api.post('/users/', user) 
}

const remove = (id) => {
    return axiosInstance.api.delete(`/users/${id}`) 
}

const activate = (id) => {
    return axiosInstance.api.put(`/users/activate/${id}`) 
}

const deactivate = (id) => {
    return axiosInstance.api.put(`/users/deactivate/${id}`) 
}

const update = (id, user) => {
    return axiosInstance.api.put(`/users/${id}`, user) 
}

const getByUser = (username) => {
    return axiosInstance.api.get(`/users/getusername/${username}`) 
}

const updatePassword = (username, password) => {
    return axiosInstance.api.put(`/users/updatepassword/${username}`, {password:password}) 
}

// eslint-disable-next-line
export default {
    get,
    create,
    remove,
    activate,
    deactivate,
    update,
    getByUser,
    getById,
    updatePassword,
}