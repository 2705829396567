import React from 'react';


const DisplayHeader = (props) => {
    return (
        <div className='flex w-fit p-2'>
                    <h3 className='text-black font-semibold w-full my-auto uppercase '>{props.title}</h3>
                    <span>{`${props.description}`}</span>
            {props.children}
        </div>
    )
}

export default DisplayHeader