import React, { useCallback, useEffect, useState, useContext } from 'react';


import userApi from '../../api/AdminResources/users';
import customerApi from '../../api/AdminResources/customers';

import ErrorAlert from '../../components/common/ErrorAlert/ErrorAlert';
import InfoAlert from '../../components/common/InfoAlert/InfoAlert';
import Loader from '../../components/UI/Loader/Loader';

import CommonTable from '../../components/common/Table/Table'
import UserModal from '../../components/AdminResources/User/UserModal'
import UpdatePasswordModal from '../../components/AdminResources/User/UpdatePasswordModal';
import { CustomerContext } from '../../hooks/context/CustomerProvider';



const UserManagement = () => {
    const { customersFromContext, 
            initialUser, 
            isValidUser,
            deleteUser,
            error,
            setError,
            info,
            setInfo,
            users, 
            setUsers} = useContext(CustomerContext);

    const [user, setUser] = useState(initialUser)
    const [changedPassword, setChangedPassword] = useState();
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [showUserModal, setShowUserModal] = useState(false)
    const [showPasswordUpdateModal, setShowPasswordUpdateModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
  
    const [formError, setFormError] = useState()


    const fetchUsers = useCallback(async () => {
        try {
            setIsLoading(true)
            const response = await userApi.get()
            const user = response.data
            setUsers(user)
            setIsLoading(false)

        } catch (err) {
            setError('Error al obtener datos del servidor')
            setIsLoading(false)
        }
    }, [setError, setUsers])



    const createUser = async () => {
        if (isValidUser(user)) {
            try {
                setIsLoading(true)
                const existeUsername = await userApi.getByUser(user.username);
                if (!existeUsername.data) {
                    delete user._id;
                    await userApi.create({ ...user, customers: selectedCustomers })
                    fetchUsers()
                    setInfo('Usuario creado satisfactoriamente')
                    newUserModalHandler()

                }
                else {
                    setError('Nombre de usuario ya existe.')
                }

            } catch (err) {
                setError('Error al crear usuario')
                setIsLoading(false)
            }
        } else {
            setFormError('Se requieren llenar todos los campos / ingrese un mail válido')
        }

    }

    const editUser = async () => {
        if (isValidUser(user)) {
            try {
                setIsLoading(true)
                if (user._id) {
                    await userApi.update(user._id, { ...user, customers: selectedCustomers })
                    fetchUsers()
                    setInfo('Usuario actualizado satisfactoriamente')
                    newUserModalHandler()

                }
            } catch (err) {
                setError('Error al crear usuario')
                setIsLoading(false)
            }

        } else {
            setFormError('Se requieren llenar todos los campos / ingrese un mail válido')
        }
    }


    const updatePassword = async () => {
        try {
            setIsLoading(true)
            await userApi.updatePassword(changedPassword.username, changedPassword.password)
            setInfo('Contraseña actualizada satisfactoriamente')
            newPasswordUpdateModal()
            fetchUsers()

        } catch (err) {
            setError('Error al actualizar contraseña')
            setIsLoading(false)
        }
    }

    const userHandler = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value
        })
    }

    const passwordHandler = (event) => {
        setChangedPassword({
            ...changedPassword,
            [event.target.name]: event.target.value
        })
    }

    const newUserModalHandler = (userID) => {
        if (typeof userID === 'string') {
            setUser(users.find((user) => user._id === userID))
        } else {
            setUser(initialUser)
        }
        setShowUserModal(prev => !prev)
    }

    const newPasswordUpdateModal = (username) => {
        setChangedPassword({ username: username })
        setShowPasswordUpdateModal(prev => !prev);
    }

    const addCustomerHandler = (event, selected) => {
        setSelectedCustomers(selected.map((el) => el._id))
    }

    useEffect(() => {
        fetchUsers()
    }, [fetchUsers,])

    useEffect(() => {
        if (
            (users && users.length >= 0) &&
            (customersFromContext && customersFromContext.length >= 0)
        ) {
            setIsLoading(false)
        }
    }, [users, customersFromContext])

    useEffect(() => {
        if (formError) {
            if (isValidUser(user)) {
                setFormError()
            }
        }

    }, [formError, user, isValidUser])

    useEffect(() => {
        if (info) {
            setTimeout(() => {
                setInfo()
            }, 5000)
        }
    }, [info, setInfo])

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError()
            }, 5000)
        }
    }, [error, setError])
    return (
        <>
            {isLoading
                ? <Loader />
                : <>
                    <div className='flex flex-col '>
                        <div className=' rounded-md shadow-md'>
                            <CommonTable
                                data={users}
                                onBtnClick={newUserModalHandler}
                                deleteRow={deleteUser}
                                passwordEdit={newPasswordUpdateModal}
                                title={"Usuarios"}
                                dataClass={'User'}
                            />
                            <UserModal
                                show={showUserModal}
                                options={customersFromContext}
                                addCustomerHandler={addCustomerHandler}
                                modalHandler={newUserModalHandler}
                                userHandler={userHandler}
                                createUser={createUser}
                                editUser={editUser}
                                formError={formError}
                                user={user}
                            />
                            <UpdatePasswordModal
                                show={showPasswordUpdateModal}
                                modalHandler={newPasswordUpdateModal}
                                updatePassword={updatePassword}
                                passwordHandler={passwordHandler}
                                formError={formError}
                                changedPassword={changedPassword}
                            />
                            <ErrorAlert error={error} />
                            <InfoAlert info={info} />
                        </div>


                    </div>
                </>
            }
        </>
    )
}

export default UserManagement