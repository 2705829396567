import { Icon, } from '@mui/material';
import React, { useState } from 'react';

import UserManagement from './User';
import CustomerManagement from './Customer';
import AppManagement from './App';


export default function Managment() {
    const views = [
        {
            path: '/admin_resources/users',
            icon: 'person',
            label: 'Usuarios'
        },
        {
            path: '/admin_resources/customers',
            icon: 'mode',
            label: 'Clientes'
        },
        {
            path: '/admin_resources/apps',
            icon: 'check',
            label: 'Aplicaciones'
        },
    ]
    const tableRender= {
        Usuarios:<UserManagement/>,
        Clientes:<CustomerManagement/>,
        Aplicaciones:<AppManagement/>,
        default:'Seleccione para Visualizar',
        }

        const [show, setShow]= useState(false);
        const [render, setRender] = useState('default');

    const showAux =(label)=>{
        setRender(label)
        setShow(true)
    }
    
  
    
    return (
        <>
        <div className='grid lg:flex'>
            <div className='lg:w-1/3 lg:mt-24 m-10'>
                <div className=' text-center mx-auto w-96 justify-center shadow-md border border-warm-red shadow-warm-red rounded-md'>
                    <div className='flex flex-col m-2 mx-auto  text-center justify-center'>
                        <div className='w-full'>
                            <h3 className=' font-semibold text-lg'>GESTIÓN DEL SISTEMA</h3>
                            <h3 className=' font-thin m-2'>Gestione aqui los elementos principales del sistema</h3>
                        </div>
                        <div className='mt-8 mb-4  flex flex-col'>
                            {views.map((view) =>
                                <button className='mx-auto transition-all duration-200 hover:cursor-pointer  hover:scale-105 justify-center align-middle mt-4 border border-warm-red shadow-md hover:shadow-xl focus:ring-1 focus:ring-offset-2 focus:ring-offset-warm-red focus:ring-warm-red border-warm-red rounded-md w-[150px]' onClick={()=>showAux(view.label)}>
                                        <h2 className='m-2 w-auto'>{view.label}</h2>
                                        <Icon style={{ fontSize: '30px', marginX: 'auto' }} >{view.icon}
                                        </Icon>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

            </div>
            {show===false?'': 
                    <div className="lg:w-2/3 h-fit  lg:mt-16 m-10 text-center border border-warm-red rounded-md shadow-md shadow-warm-red">
                     
                        {tableRender[render]}
                        
                    </div>
                }
           
        </div>

        </>

    )
}